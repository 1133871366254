import React from 'react';
import cx from 'classnames';
import { Icon } from '../Icons';
import styles from './TextInput.module.css';

interface Props {
  disabled?: boolean;
  icon?: string;
}

export const LeftIcon = ({ disabled, icon }: Props) => {
  if (!icon) {
    return null;
  }
  const classNameSpan = cx(
    'absolute flex absolute--center abbey',
    { 'not-allowed': disabled },
    styles.iconPositiontLeft,
  );
  return (
    <span className={classNameSpan}>
      <Icon name={icon} />
    </span>
  );
};
