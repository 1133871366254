import React from 'react';
import { navigate } from 'gatsby';
import { Footer } from '../Footer';
import { Menu } from '../Menu';
import './autocomplete.css';
import { RegisterModelForm } from './components/RegisterModelForm';
import styles from './styles.module.css';

export const RegisterNewModel = () => {
  const user = localStorage.getItem('user');

  const checkIfAdmin = () => {
    let isAdmin = false;

    if (user) {
      const {
        profile: { admin },
      } = JSON.parse(user);

      isAdmin = admin;
    }

    if (!isAdmin) {
      navigate('/meus-anuncios');
    }

    return isAdmin;
  };

  if (!checkIfAdmin()) {
    return null;
  }

  return (
    <div>
      <div>
        <Menu menuHasTwoColors={false} />
      </div>

      <RegisterModelForm />

      <div className={styles.wrapperFooter}>
        <Footer />
      </div>
    </div>
  );
};
