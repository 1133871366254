import React, { ReactNode } from 'react';

interface Props {
  children: ReactNode;
}

export const ErrorMessage = ({ children }: Props) => {
  if (!children) {
    return null;
  }
  return (
    <span aria-live="assertive" className="purpley-pink f11 pl12 fw4 db lh-copy mt4">
      {children}
    </span>
  );
};
