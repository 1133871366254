import React, { useState, useEffect } from 'react';
import Axios from 'axios';
import { Button } from '../../../../components/Button';
import { Icon } from '../../../../components/Icons';
import {
  ModalSuccessAndError,
  modalErrorDefault,
  modalSuccesDefault,
} from '../../../../components/ModalSuccessAndError';
import { Select } from '../../../../components/Select';
import { TextInput } from '../../../../components/TextInput';
import { models } from '../../services/Models';
import styles from '../../styles.module.css';

export const RegisterModelForm = () => {
  const [name, setName] = useState('');
  const [categories, setCategories] = useState([]);
  const [parentCategorie, setParentCategorie] = useState(null);

  const [loading, setLoading] = useState(false);
  const [configModal, setConfigModal] = useState(modalErrorDefault);
  const [successOrErrorOpen, setSuccessOrErrorOpen] = useState(false);

  const [errorName, setErrorName] = useState('');

  useEffect(() => {
    Axios.get(`${process.env.BASE_API_DEV}/partCategories`).then(response => {
      const categories = [{ value: null, label: 'NENHUMA' }];
      const { data } = response;
      data.forEach(item => {
        categories.push({
          value: item.id,
          label: item.name,
        });

        item.children.forEach(child => {
          categories.push({
            value: child.id,
            label: `${item.name} > ${child.name}`,
          });
        });
      });
      setCategories(categories);
    });
  }, []);

  const validateName = () => {
    if (name === '') {
      setErrorName('Campo Obrigatório');
      return false;
    }

    setErrorName('');
    return true;
  };

  const checkErrors = () => {
    const checkName = validateName();

    return checkName;
  };

  const controlSucessOrError = () => setSuccessOrErrorOpen(!successOrErrorOpen);

  const clickOkModalSOE = () => {
    setSuccessOrErrorOpen(!successOrErrorOpen);

    if (configModal?.success === true) {
      location.reload();
    }
  };

  const onClick = async () => {
    if (checkErrors()) {
      const params = {
        name,
        parent: parentCategorie,
      };
      setLoading(true);
      setConfigModal(modalErrorDefault);
      try {
        await models.create(params).then(res => {
          if (res.status && res.status >= 200 && res.status < 300) {
            setConfigModal(modalSuccesDefault);
          } else if (res.status && res.status === 400) {
            setConfigModal({
              success: false,
              title: 'Ops!',
              subTitle: 'esse modelo já está cadastrado',
            });
          }
        });
      } catch (e) {
        console.log(e);
      }
      setLoading(false);
      setSuccessOrErrorOpen(true);
    }
  };

  const handleName = event => {
    setName(event.target.value);
  };

  const handleParentCategorie = event => {
    const { value } = event;
    setParentCategorie(value);
  };

  return (
    <div style={{ marginTop: '90px' }}>
      <div style={{ marginLeft: '15%' }}>
        <Icon name="SvgIconEdit" className="mr8 red" />
        <span className="fw7 f20 f30-m f30-l abbey">ADICIONAR NOVO MODELO DE PEÇAS</span>
      </div>
      <div className={styles.wrapperContent}>
        <div className="mt16">
          <TextInput
            name="modelName"
            id="modelNameInput"
            labeltext="Nome"
            value={name}
            onChange={handleName}
            onBlur={validateName}
            errorMessage={errorName}
          />
        </div>

        <div className="mt16">
          <Select
            key={1}
            options={categories}
            selected={categories[0]}
            noDropdownIndicator={false}
            title="Categoria Pai"
            onChange={handleParentCategorie}
            placeholder=""
          />
        </div>

        <div style={{ width: '40%', marginTop: '30px' }}>
          <Button loading={loading} classButton="mb24" onClick={onClick}>
            SALVAR
          </Button>
        </div>

        <ModalSuccessAndError
          closeModal={controlSucessOrError}
          isOpen={successOrErrorOpen}
          actionBtn={clickOkModalSOE}
          {...configModal}
        />
      </div>
    </div>
  );
};
