import React from 'react';
import cx from 'classnames';
import { Icon } from '../Icons';
import styles from './TextInput.module.css';

interface Props {
  disabled?: boolean;
  icon?: string;
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  iconTitle?: string;
  iconId?: string;
}

export const InputButton = ({ icon, disabled, onClick, iconTitle, iconId }: Props) => {
  if (!icon) {
    return null;
  }
  const classNameButton = cx(
    'bg-transparent absolute flex outline-0 pointer bn absolute--center',
    { 'not-allowed': disabled },
    styles.floatLabelPositionButton,
  );
  return (
    <button
      type="button"
      tabIndex={0}
      className={classNameButton}
      onClick={onClick}
      title={iconTitle}
      aria-label={iconTitle}
    >
      <Icon customId={iconId} name={icon} />
    </button>
  );
};
