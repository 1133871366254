import React from 'react';
import cx from 'classnames';
import { callAll } from '../../utils/callAll';
import { Icon } from '../Icons';
import { ErrorMessage } from './ErrorMessage';
import { InputButton } from './InputButton';
import { LeftIcon } from './LeftIcon';
import styles from './TextInput.module.css';

interface TextInputProps {
  id: string;
  iconleft?: string;
  iconright?: string;
  errorMessage?: string;
  labeltext: string;
  type?: string;
  disabled?: boolean;
  maxLength?: number;
  name?: string;
  value?: number | string;
  className?: string;
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onFocus?: (event: React.FocusEvent<HTMLInputElement>) => void;
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
  mask?: (value: string) => string;
  iconTitle?: string;
  iconId?: string;
  required?: boolean;
}
interface State {
  hasFocusOrValue: boolean;
  value: string | number;
}

export class TextInput extends React.Component<TextInputProps, State> {
  state: State = {
    hasFocusOrValue: false,
    value: '' || 0,
  };
  static getDerivedStateFromProps(nextProps: TextInputProps, prevState: State) {
    if (nextProps.value !== prevState.value) {
      return { value: nextProps.value, hasFocusOrValue: false };
    }
    return null;
  }

  onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.persist();
    const { mask, onChange } = this.props;

    this.setState({ value: e.target.value });

    if (mask) {
      e.target.value = mask(e.target.value);
    }

    return onChange && onChange(e);
  };

  onFocus = () => this.setState({ hasFocusOrValue: true });

  onBlur = () => {
    const { value } = this.state;

    const inputHasValue = value !== '';
    this.setState({ hasFocusOrValue: inputHasValue });
  };

  handleErrorMessage = () => {
    const { errorMessage } = this.props;

    if (errorMessage) {
      return <ErrorMessage>{errorMessage}</ErrorMessage>;
    }
  };

  renderErrorIcon = () => {
    const { errorMessage } = this.props;
    if (!errorMessage) {
      return null;
    }
    return <Icon name="SvgIconPurpleError" className="absolute top--8 right--8" />;
  };

  render() {
    const { value, hasFocusOrValue } = this.state;
    const {
      disabled,
      iconleft,
      errorMessage,
      iconright,
      onChange,
      onFocus,
      onBlur,
      type = 'text',
      labeltext,
      onClick,
      mask,
      className,
      iconTitle,
      iconId,
      required,
      ...rest
    } = this.props;

    const classInput = cx(
      'f14 fw4 abbey b--gray w-100 br3 outline-0 ba bg-white',
      styles.floatLabelInput,
      {
        'bw1 b--purpley-pink': errorMessage,
        'bg-alto b--abbey not-allowed': disabled,
        [styles.valueInputWithIconLeft]: iconleft,
        pl12: !iconleft,
      },
      className,
    );

    const classLabel = cx('f14 fw4 absolute absolute--center transition-ease', {
      'not-allowed': disabled,
      [styles.pen]: !disabled,
      [styles.floatLabelPositionWithIcon]: iconleft,
      [styles.floatLabelPositionWithoutIcon]: !iconleft,
      [styles.floatLabelUp]: hasFocusOrValue || value === 0 || value,
      boulder: hasFocusOrValue,
      abbey: !hasFocusOrValue,
    });

    return (
      <>
        <div className="relative w-100">
          <LeftIcon disabled={disabled} icon={iconleft} />
          <input
            type={type}
            autoComplete="off"
            className={classInput}
            value={value}
            onChange={callAll(onChange, this.onChange)}
            onFocus={callAll(onFocus, this.onFocus)}
            onBlur={callAll(this.onBlur, onBlur)}
            disabled={disabled}
            aria-label={'Campo de entrada de texto para ' + labeltext}
            required={required}
            {...rest}
          />
          <label htmlFor={this.props.id} className={classLabel}>
            {labeltext}
          </label>
          {this.renderErrorIcon()}
          <InputButton
            disabled={disabled}
            icon={iconright}
            onClick={onClick}
            iconTitle={iconTitle}
            iconId={iconId}
          />
        </div>
        {this.handleErrorMessage()}
      </>
    );
  }
}
