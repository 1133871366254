import { axiosInstanceWithAuth } from '../../../services/axios-instance';

interface CreateProps {
  name: string;
  parent?: number;
}

export const models = {
  create: async (params: CreateProps) => {
    return await axiosInstanceWithAuth
      .post('/partCategories', params)
      .then(resp => resp)
      .catch(err => err);
  },
  // create: async (params: CreateProps) => {
  //   return await Axios.post(
  //     `${process.env.BASE_API_DEV}/partCategories`,
  //     params,
  //     {
  //       validateStatus: status =>
  //         status === 200 || status === 400 || status === 422 || status === 404,
  //     },
  //   );
  // },
};
